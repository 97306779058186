import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login_associado/login',
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'login_associado',
        loadChildren: () =>
            import('modules/auth-associado/auth-associado.routing.module').then(
                m => m.AuthRoutingModule
            ),
    },
    {
        path: 'menu',
        loadChildren: () =>
            import('modules/menu/menu-routing.module').then(m => m.MenuRoutingModule),
    },
    {
        path: 'perfil',
        loadChildren: () =>
            import('modules/perfil/perfil-routing.module').then(m => m.PerfilRoutingModule),
    },
    {
        path: 'funcionario',
        loadChildren: () =>
            import('modules/funcionario/funcionario-routing.module').then(
                m => m.FuncionarioRoutingModule
            ),
    },
    {
        path: 'associado',
        loadChildren: () =>
            import('modules/associado/associado-routing.module').then(
                m => m.AssociadoRoutingModule
            ),
    },
    {
        path: 'associado-sem-desativar',
        loadChildren: () =>
            import('modules/associado-sem-desativar/associado-routing.module').then(
                m => m.AssociadoRoutingModule
            ),
    },
    {
        path: 'inicio',
        loadChildren: () =>
            import('modules/inicio/inicio-routing.module').then(m => m.InicioRoutingModule),
    },
    {
        path: 'tipo_advogado',
        loadChildren: () =>
            import('modules/tipo-advogado/tipo_advogado-routing.module').then(
                m => m.TipoAdvogadoRoutingModule
            ),
    },
    {
        path: 'saude',
        loadChildren: () =>
            import('modules/saude/saude-routing.module').then(m => m.SaudeRoutingModule),
    },
    {
        path: 'barbearia',
        loadChildren: () =>
            import('modules/barbearia/barbearia-routing.module').then(
                m => m.BarbeariaRoutingModule
            ),
    },
    {
        path: 'agendamento-associado',
        loadChildren: () =>
            import('modules/agendamento-associado/agendamento-associado-routing.module').then(
                m => m.AgendamentoAssociadoRoutingModule
            ),
    },
    {
        path: 'agendamento-hotelaria',
        loadChildren: () =>
            import('modules/agendamento-hotelaria/agendamento-hotelaria-routing.module').then(
                m => m.AgendamentoHotelariaServiceeRoutingModule
            ),
    },
    {
        path: 'gerencia-agendamento',
        loadChildren: () =>
            import('modules/gerencia-agendamentos/gerencia-agendamentos-routing.module').then(
                m => m.GerenciaAgendamentoRoutingModule
            ),
    },
    {
        path: 'gerencia-agendamento-geral',
        loadChildren: () =>
            import(
                'modules/gerencia-agendamentos-geral/gerencia-agendamento-geral-routing.module'
            ).then(m => m.GerenciaAgendamentoRoutingModule),
    },
    {
        path: 'gerencia-horarios',
        loadChildren: () =>
            import('modules/gerencia-horarios/gerencia-horarios-routing.module').then(
                m => m.GerenciaHorariosRoutingModule
            ),
    },
    {
        path: 'juridico',
        loadChildren: () =>
            import('modules/juridico/juridico-routing.module').then(m => m.JuridicoRoutingModule),
    },
    {
        path: 'calendario',
        loadChildren: () =>
            import('modules/calendario/calendario-routing.module').then(
                m => m.CalendarioRoutingModule
            ),
    },
    {
        path: 'hotelaria',
        loadChildren: () =>
            import('modules/hotelaria/hotelaria-routing.module').then(
                m => m.HotelariaRoutingModule
            ),
    },
    {
        path: 'gerencia-agendamento-hotelaria',
        loadChildren: () =>
            import(
                'modules/gerencia-agendamento-hotelaria/gerencia-agendamento-hotelaria-routing.module'
            ).then(m => m.GerenciaAgendamentoHotelariaRoutingModule),
    },
    {
        path: 'gerencia-agendamento-parceiro',
        loadChildren: () =>
            import(
                'modules/gerencia-agendamento-parceiro/gerencia-agendamento-parceiro-routing.module'
            ).then(m => m.GerenciaAgendamentoParceiroRoutingModule),
    },
    {
        path: 'quarto',
        loadChildren: () =>
            import('modules/quarto/quarto-routing.module').then(m => m.QuartoRoutingModule),
    },
    {
        path: 'parceiro',
        loadChildren: () =>
            import('modules/parceiro/parceiro-routing.module').then(m => m.ParceiroRoutingModule),
    },
    {
        path: 'agendamento-parceiro',
        loadChildren: () =>
            import('modules/agendamento-parceiro/agendamento-parceiro-routing.module').then(
                m => m.AgendamentoparceiroServiceeRoutingModule
            ),
    },
    {
        path: 'conta',
        loadChildren: () =>
            import('modules/conta/conta-routing.module').then(m => m.ContaRoutingModule),
    },
    {
        path: 'dependente',
        loadChildren: () =>
            import('modules/dependente/dependente-routing.module').then(
                m => m.dependenteRoutingModule
            ),
    },
    {
        path: 'relatorio-associado',
        loadChildren: () =>
            import('modules/relatorio-associado/relatorio-associado-routing.module').then(
                m => m.relatorioRoutingModule
            ),
    },
    {
        path: 'relatorio-agendamentos-por-funcionario',
        loadChildren: () =>
            import(
                'modules/relatorio-agendamentos-por-funcionario/relatorio-agendamentos-por-funcionario-routing.module'
            ).then(m => m.relatorioRoutingModule),
    },
    {
        path: 'altera-senha-funcionario',
        loadChildren: () =>
            import('modules/altera-senha-funcionario/altera-senha-funcionario-routing.module').then(
                m => m.funcionarioRoutingModule
            ),
    },
    {
        path: 'gerencia-agendamentos-por-departamento',
        loadChildren: () =>
            import(
                'modules/gerencia-agendamentos-por-departamento/gerencia-agendamentos-por-departamento-routing.module'
            ).then(m => m.GerenciaAgendamentoRoutingModule),
    },
    {
        path: 'gerencia-agendamentos-por-modulo',
        loadChildren: () =>
            import(
                'modules/gerencia-agendamentos-por-modulo/gerencia-agendamentos-por-modulo-routing.module'
            ).then(m => m.GerenciaAgendamentoRoutingModule),
    },
    {
        path: 'cda',
        loadChildren: () => import('modules/cda/cda-routing.module').then(m => m.CDARoutingModule),
    },
    {
        path: 'agendamento-funcionario',
        loadChildren: () =>
            import('modules/agendamento-funcionario/agendamento-funcionario-routing.module').then(
                m => m.AgendamentoFubncionarioRoutingModule
            ),
    },
    {
        path: 'associado-financeiro',
        loadChildren: () =>
            import('modules/associado-financeiro/associado-financeiro-routing.module').then(
                m => m.AssociadoRoutingModule
            ),
    },
    {
        path: 'documento-associado',
        loadChildren: () =>
            import('modules/documento-associado/documento-associado-routing.module').then(
                m => m.DocumentoAssociadoRoutingModule
            ),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    {
        path: 'version',
        loadChildren: () =>
            import('modules/utility/utility-routing.module').then(m => m.UtilityRoutingModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
